<template>
  <main id="single">
    <navbar></navbar>

    <section class="hero is-ligth is-fullheight-with-navbar">
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <Footer />
    </section>
  </main>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Nav/Navbar.vue'
import Footer from '@/components/Footer/Footerbar.vue'

@Component({
  components: {
    Navbar,
    Footer,
  },
})
export default class SingleLayout extends Vue {}
</script>
<style lang="scss">
#single {
  .hero .container {
    width: 100%;
  }
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
